<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">Neuen Kunden anlegen</span>
			</h3>
		</div>
		<div class="card-body py-3">
			<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
			<div class="row" v-if="!pageProcesses.isLoading">
				<div class="col-12">
					<div class="row">
						<div class="col-12">
							<h3>Organisation</h3>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Organisation">
								<b-form-select v-model="customer.organization.id" :options="organizations" text-field="name" value-field="id" v-on:change="selectOrganization"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Name">
								<b-input type="text" v-model="customer.organization.name"></b-input>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Beschreibung">
								<b-input type="text" v-model="customer.organization.description"></b-input>
							</b-form-group>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<h3>Unternehmen</h3>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Unternehmen">
								<b-form-select v-model="customer.company.id" :options="companyList" text-field="name" value-field="id" v-on:change="selectCompany"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Name">
								<b-input type="text" v-model="customer.company.name"></b-input>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Beschreibung">
								<b-input type="text" v-model="customer.company.description"></b-input>
							</b-form-group>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<h3>Benutzer</h3>
						</div>
						<div class="col-12">
							<b-form-group label="Benutzer anlegen?">
								<b-form-radio-group v-model="customer.user.add">
									<b-form-radio :value="false">Nein</b-form-radio>
									<b-form-radio :value="true">Ja</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Vorname">
								<b-input type="text" v-model="customer.user.first_name"></b-input>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="Nachname">
								<b-input type="text" v-model="customer.user.last_name"></b-input>
							</b-form-group>
						</div>
						<div class="col-12 col-md-4">
							<b-form-group label="E-Mail">
								<b-input type="text" v-model="customer.user.email"></b-input>
							</b-form-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import {SET_ACTION} from "@/core/services/store/actions.module";
import ApiService from "@/core/services/api.service";
import {PRINOR_ACTIONS, PRINOR_PROCESSES} from "@/core/services/actions.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import ContentLoading from "@/view/component/misc/ContentLoading";
import {mapGetters} from "vuex";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import Swal from "sweetalert2";

export default {
	name: 'SuperCustomer',
	components: {ContentLoading},
	data() {
		return {
			saveButtonId: '',
			customer: {
				organization: {
					id: 'new',
					name: '',
					description: '',
				},
				company: {
					id: 'new',
					name: '',
					description: '',
				},
				user: {
					add: false,
					first_name: '',
					last_name: '',
					email: '',
				}
			},
			organizations: [],
			companies: [],
		};
	},
	computed: {
		...mapGetters(['pageProcesses']),
		companyList: function() {
			let list = this.companies.filter(value => { return value.organization_id === this.customer.organization.id });
			return list;
		}
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: 'Super Administration',
			},
			{ title: 'Neuer Kunde' },
		]);
		this.saveButtonId = PRINOR_ACTIONS.create(this, 'Speichern', this.onUpdate, 'success', '', false, !this.hasPermission('super.admin'));

		PRINOR_PROCESSES.isLoading();
		this.onGet();
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		selectOrganization: function(val) {
			let organization = this.organizations.filter(value => { return value.id === val; });
			if (organization.length === 1) {
				this.customer.organization.id = organization[0].id;
				this.customer.organization.name = organization[0].name;
				this.customer.organization.description = organization[0].description;

				if (this.companyList.length > 0) {
					this.selectCompany(this.companyList[0].id);
				}
			}
		},
		selectCompany: function(val) {
			let company = this.companies.filter(value => { return value.id === val; });
			if (company.length === 1) {
				this.customer.company.id = company[0].id;
				this.customer.company.name = company[0].name;
				this.customer.company.description = company[0].description;
			}
		},
		onGet: function() {
			this.isLoading = true;

			this.customer = {
				organization: {
					id: 'new',
						name: '',
						description: '',
				},
				company: {
					id: 'new',
						name: '',
						description: '',
				},
				user: {
					add: false,
						first_name: '',
						last_name: '',
						email: '',
				}
			};

			this.get().then(data => {
				this.organizations = data.data.organizations;
				this.companies = data.data.companies;

				this.organizations.push({
					id: 'new',
					name: 'Neu anlegen',
					description: '',
				});

				this.companies.push({
					id: 'new',
					name: 'Neu anlegen',
					description: '',
					organization_id: 'new',
				});
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('super/customer').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		onUpdate() {
			PRINOR_PROCESSES.isUpdating();
			this.update().then(data => {
				let htmlContent = '<h5>Folgende Daten für den Kunden notieren!</h5>' + '<table width="100%" style="text-align: left;">' +
					'<tr>' +
					'<td colspan="2" style="text-align: center;font-size: 1.5rem;padding: 0.5rem;">Organisation</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Name:</td>' +
					'<td>' + data.data.organization.name + '</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Beschreibung:</td>' +
					'<td>' + data.data.organization.description + '</td>' +
					'</tr>' +
					'<tr>' +
					'<td colspan="2" style="text-align: center;font-size: 1.5rem;padding: 0.5rem;">Unternehmen</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Name:</td>' +
					'<td>' + data.data.company.name + '</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Beschreibung:</td>' +
					'<td>' + data.data.company.description + '</td>' +
					'</tr>';

				if (data.data.user.email != null) {
					htmlContent += '<tr>' +
						'<td colspan="2" style="text-align: center;font-size: 1.5rem;padding: 0.5rem;">Benutzer</td>' +
						'</tr>' +
						'<tr>' +
						'<td>Vorname:</td>' +
						'<td>' + data.data.user.first_name + '</td>' +
						'</tr>' +
						'<tr>' +
						'<td>Nachname:</td>' +
						'<td>' + data.data.user.last_name + '</td>' +
						'</tr>' +
						'<tr>' +
						'<td>Email:</td>' +
						'<td>' + data.data.user.email + '</td>' +
						'</tr>' +
						'<tr>' +
						'<td>Passwort:</td>' +
						'<td>' + data.data.password + '</td>' +
						'</tr>';
				}

				htmlContent += '</table>';
				
				Swal.fire({
					title: 'Kundendaten',
					html: htmlContent,
					icon: 'warning',
					confirmButtonText: 'Notiert!',
					showCancelButton: false,
					customClass: {
						confirmButton: 'btn btn-danger',
						content: 'swal2-container-max-height',
					},
					heightAuto: false,
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
				}).then(result => {
					if (result.isConfirmed || result.isDismissed) {
						this.onGet();
					}
				});
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('super/customer', this.customer).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
