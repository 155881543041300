<template>
	<div id="loading">
		<vue-loaders-ball-pulse-sync color="#000" :scale="size"></vue-loaders-ball-pulse-sync>
	</div>
</template>

<script>
export default {
	name: 'ContentLoading',
	props: {
		size: [String, Number],
	},
};
</script>

<style scoped>
#loading {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
